import React from "react";
import Seo from "../components/seo/index";
import Layout from "../layout/index";
import MyRegisterationlistpage from "../components/sharedComponents/myregisteration/myregiterationlist";

export default function MyRegisterationlist() {
  return (
    <>
      <div>
        <Seo title="Green Heartfulness Run 2024 - My Registrations" />
        <Layout isSticky>
          <section>
            <MyRegisterationlistpage />
          </section>
        </Layout>
      </div>
    </>
  );
}
